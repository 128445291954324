import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Calendar, Col, Row, Button, Space, Input, Checkbox, Spin, Popover, Card, Alert, Result, Select } from "antd";
import dayjs from "dayjs";
import 'dayjs/locale/lt';
import locale from 'antd/es/date-picker/locale/lt_LT';
import dayLocaleData from 'dayjs/plugin/localeData';
import CardPartial from "./partials/Card";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import type { Dayjs } from "dayjs";
import type { InputRef } from 'antd';
import { ValidationError } from 'yup';
import { UserFormInterface, formSchema } from "./definitions";
import InputHolder from "./partials/Input";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import debounce from 'lodash.debounce';
import { Tooltip } from "antd";

interface City {
  uuid: string;
  name: string;
}



// List of services that are unavailable in Alytus
const unavailableServicesInAlytus = [
  "Dūmų burbulų ŠOU",
  "Akimirkos spausdintose nuotraukose"
];

function App() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  dayjs.extend(dayLocaleData);
  dayjs.locale('lt');

  const servicesEndpoint = process.env.REACT_APP_API_URL + "/api/service/get/all";
  const invitesEndPoint = process.env.REACT_APP_API_URL + "/api/invitations/by_city";
  const timesEndpoint = process.env.REACT_APP_API_URL + "/api/calendar/get/freetimes";
  const discountEndpoint = process.env.REACT_APP_API_URL + "/api/discount/check";
  const formEndpoint = process.env.REACT_APP_API_URL + "/api/order/add";

  const guestOverflow = 14;

  const inputStyle: Object = {
    width: "100%"
  };




  const [services, setServices] = useState<any[]>([]);
  const [price, setPrice] = useState(0);
  const [discountStatus, setDiscountStatus] = useState<Boolean | null>(null);
  const [invitations, setInvitations] = useState<any[]>([]);
  const [discount, setDiscount] = useState({
    discount_amount: 0,
    uuid: ''
  });
  const [loading, setLoading] = useState(true);

  const [activeTime, setActiveTime] = useState<string | null>(null);
  const [activeInvite, setActiveInvite] = useState<string | null>(null);
  const [availableTimes, setAvailableTimes] = useState<any[]>([]);

  const [showEndscreen, setShowendScreen] = useState<boolean>(false);
  const [isPosting, setPosting] = useState<boolean>(false);

  const [criticalError, setCriticalError] = useState<boolean>(false);
  const [combo, setCombo] = useState<boolean>(false);
  const [selectedCity, setSelectedCity] = useState<City | null>();
  const [cities, setCities] = useState<City[]>([]);
  const [nonCriticalError, setNonCriticalError] = useState(false);
  const discountRef = useRef<InputRef>(null);

  const resetDiscountState = () => {
    setUserForm(prevForm => ({ ...prevForm, discount_code: "" }));
    setDiscountStatus(null);
    setDiscount({ discount_amount: 0, uuid: '' });
    if (discountRef.current && discountRef.current.input) {
      discountRef.current.input.value = "";
    }
  };


  useEffect(() => {
    let isMounted = true;
    // console.log("Fetching cities...");

    if (isMounted) {
      axios.get(process.env.REACT_APP_API_URL + '/api/cities')
        .then(res => {
          // console.log("Cities fetched: ", res.data);
          setCities(res.data);
          setLoading(false);
        })
        .catch((err) => {
          // console.error("Error fetching cities: ", err);
          setCriticalError(true);
          setLoading(false);
        });
    }

    return () => {
      isMounted = false;
    };
  }, []);


  // Disable specific dates in the calendar
  const calendarFilter = (current: Dayjs): boolean => {
    const busyDate = dayjs("2024-09-05");

    if (selectedCity?.name === "Alytus" && current.isSame(busyDate, 'day')) {
      return true; // Disable September 5th, 2024 for Alytus
    }

    return false;
  };

  const postData = () => {
    setErrors({});
    if (!selectedCity) {
      setErrors({ city_uuid: "Please select a city" });
      return;
    }
    setPosting(true);
    console.log("Posting data...");
    formSchema.validate({ ...userForm, city_uuid: selectedCity.uuid }, { abortEarly: false }).then(() => {
      axios.post(formEndpoint, { ...userForm, city_uuid: selectedCity.uuid }).then(res => {
        if (res.data.statusCode !== 200) {
          console.error("Critical error posting data: ", res.data);
          setCriticalError(true)
        } else {
          console.log("Data posted successfully: ", res.data);
          setShowendScreen(true);
        }
      }).catch(err => {
        console.error("Error posting data: ", err);
        setCriticalError(true)
      }).finally(() => {
        setPosting(false);
      })
    }).catch((error) => {
      if (error instanceof ValidationError) {
        let obj: ErrorInterface = {};
        for (const e of error.inner) {
          if (e.path !== undefined) {
            obj[e.path] = e.message
          }
        }
        setErrors(obj);
      }
      console.error("Validation error: ", error);
      setPosting(false);
    })
  };

  const calendarChange = (e: Dayjs) => {
    setActiveTime(null);
    setUserForm({
      ...userForm,
      time_selected: null
    });

    if (!selectedCity) {
      setErrors({ city_uuid: "Please select a city" });
      return;
    }
    // Reset discount state when date changes
    resetDiscountState();
    setLoading(true);
    console.log(`Fetching times for date: ${e.format('YYYY-MM-DD')} and city_uuid: ${selectedCity.uuid}`); // Debug log

    axios.get(timesEndpoint, {
      params: {
        date: e.format('YYYY-MM-DD'),
        city_uuid: selectedCity.uuid
      }
    }).then(res => {
      console.log("Received available times: ", res.data); // Debug log

      // Check if the response data is valid and not empty
      if (res.data && res.data.length > 0) {
        setUserForm({
          ...userForm,
          "day_selected": e.format('YYYY-MM-DD')
        });
        setAvailableTimes(res.data);
        setNonCriticalError(false);  // Reset error if data is valid
      } else {
        // Handle the case when no times are available
        setAvailableTimes([]);
        setNonCriticalError(false);
      }

      setLoading(false);
    }).catch((err) => {
      console.error("Error fetching times: ", err);
      setNonCriticalError(true);  // This triggers the error display logic
      setLoading(false);
    });
  };

  const handleCalendarErrorReset = () => {
    // Reset calendar to the next day
    const nextDay = dayjs().add(1, 'day');

    // Update user form state
    setUserForm({
      ...userForm,
      "day_selected": nextDay.format('YYYY-MM-DD'),
    });


    // Fetch available times for the next day and reset state accordingly
    if (selectedCity) {
      setLoading(true);
      axios.get(timesEndpoint, {
        params: {
          date: nextDay.format('YYYY-MM-DD'),
          city_uuid: selectedCity.uuid,
        }
      })
        .then(res => {
          setAvailableTimes(res.data);
          setActiveTime(null);
          setNonCriticalError(false);  // Clear the non-critical error
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching times for the next day: ", err);
          setLoading(false);
          // Handle error accordingly, you might want to keep the error state or inform the user again
        });
    } else {
      setNonCriticalError(false);  // Clear the non-critical error
    }
  };

  interface ErrorInterface {
    [key: string]: string;
  }
  const [errors, setErrors] = useState<ErrorInterface>({});

  const startDate = dayjs().add(1, 'day');

  const [userForm, setUserForm] = useState<UserFormInterface>({
    customer_email: "",
    customer_parent_name: "",
    customer_name: "",
    customer_age: 0,
    customer_phone_no: "",
    day_selected: "",
    customer_address: "",
    customer_guests: 0,
    time_selected: "",
    invitation_type: "",
    discount_code: "",
    services_selected: [],
    print_invites: "false",

  });

  useEffect(() => {
    // Reset all state variables on page reload
    setServices([]);
    setPrice(0);
    setDiscountStatus(null);
    setInvitations([]);
    setDiscount({
      discount_amount: 0,
      uuid: ''
    });
    setLoading(true);
    setActiveTime(null);
    setActiveInvite(null);
    setAvailableTimes([]);
    setPosting(false);
    setCriticalError(false);
    setCombo(false);
    setSelectedCity(null);
    setCities([]);
    setErrors({});
    setUserForm({
      customer_email: "",
      customer_parent_name: "",
      customer_name: "",
      customer_age: 0,
      customer_phone_no: "",
      day_selected: "",
      customer_address: "",
      customer_guests: 0,
      time_selected: "",
      invitation_type: "",
      discount_code: "",
      services_selected: [],
      print_invites: "false",
    });

    console.log("Fetching cities...");
    axios.get(process.env.REACT_APP_API_URL + '/api/cities')
      .then(res => {
        console.log("Cities fetched: ", res.data);
        setCities(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching cities: ", err);
        setCriticalError(true);
        setLoading(false);
      });
  }, []); // Empty dependency array ensures this runs only on mount

  const fillableForm = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(`Form changed: ${event.target.name} = ${event.target.value}`);
    setUserForm({ ...userForm, [event.target.name]: event.target.value })
  };

  let oldPrice = 0;

  useEffect(() => {
    console.log("Fetching services...");
    axios.get(servicesEndpoint).then(res => {
      console.log("Services fetched: ", res.data);
      setServices(res.data);
      setLoading(false);
    }).catch((err) => {
      console.error("Error fetching services: ", err);
      setCriticalError(true);
    })
  }, [servicesEndpoint]);

  const fetchInvitationsByCity = useCallback((cityUUID: string) => {
    console.log(`Fetching invitations for city: ${cityUUID}`);
    axios.get(`${invitesEndPoint}/${cityUUID}`).then(res => {
      console.log("Invitations fetched: ", res.data);
      setInvitations(res.data);
    }).catch((err) => {
      console.error("Error fetching invitations: ", err);
      setCriticalError(true);
    });
  }, [invitesEndPoint]);

  useEffect(() => {
    if (selectedCity) {
      fetchInvitationsByCity(selectedCity.uuid);
    }
  }, [selectedCity, fetchInvitationsByCity]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    await executeRecaptcha('yourAction');
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const debouncedFetchTimes = useCallback(
    debounce((cityUUID: string, date: string) => {
      console.log(`Debounced fetch times for city: ${cityUUID} on date: ${date}`);
      axios
        .get(timesEndpoint, {
          params: {
            date: date,
            city_uuid: cityUUID,
          },
        })
        .then((res) => {
          console.log(`Fetched times for city: ${cityUUID} on date: ${date}`);
          setAvailableTimes(res.data);
          setUserForm((prevUserForm) => ({
            ...prevUserForm,
            day_selected: date,
          }));
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching available times:', error);
          setCriticalError(true);
          setLoading(false);
        });
    }, 300),
    [timesEndpoint]
  );

  const handleCityChange = (city: City) => {
    console.log(`Selected city: ${city.name} (UUID: ${city.uuid})`);
    setSelectedCity(city);
    // Reset discount state when city changes
    resetDiscountState();
    // Check if a date is already selected, if not, default to tomorrow's date
    const selectedDate = userForm.day_selected || dayjs().add(1, "day").format("YYYY-MM-DD");

    setUserForm((prevUserForm) => ({
      ...prevUserForm,
      city_uuid: city.uuid,
      day_selected: selectedDate, // Preserve the selected date
      time_selected: "",
      services_selected: [],
      invitation_type: "",
      print_invites: "false",
      discount_code: "",
    }));

    setActiveTime(null);
    setAvailableTimes([]);
    setPrice(0);
    setLoading(true);

    // Fetch times for the selected date
    debouncedFetchTimes(city.uuid, selectedDate);
  };


  useEffect(() => {
    if (combo === true) {
      setPrice((price) => {
        return price - 10;
      });
    } else {
      setPrice((price) => {
        return price + 10;
      });
    }
  }, [combo]);

  const calculateDiscount = useCallback(() => {
    let discountAmount = 0;

    // Apply combo discount
    if (combo) {
      discountAmount += 10;
    }

    // Apply discount code amount
    if (discount.discount_amount > 0) {
      discountAmount += discount.discount_amount;
    }

    return discountAmount;
  }, [combo, discount.discount_amount]);

  const totalDiscount = calculateDiscount();


  const handleDiscountCodeCheck = () => {
    if (userForm.discount_code !== "" && selectedCity) {
      console.log("Checking discount code...");
      axios.get(discountEndpoint, {
        params: {
          code: userForm.discount_code,
          city_uuid: selectedCity.uuid
        }
      }).then(res => {
        console.log("Discount code response: ", res.data);

        if (res.data.status === 200) {
          setDiscountStatus(true);
          setDiscount(res.data.discount);
        } else {
          setDiscountStatus(false);
          setDiscount({
            discount_amount: 0,
            uuid: ''
          });
        }
      }).catch((err) => {
        console.error("Error checking discount code: ", err);
        setDiscountStatus(false);
        setDiscount({
          discount_amount: 0,
          uuid: ''
        });
      });
    }
  };

  useEffect(() => {
    calculateDiscount();
  }, [price, userForm.services_selected, discount.discount_amount, combo]);

  const discountedPrice = price - totalDiscount;

  return (
    <div style={{ maxWidth: "400px" }}>
      {criticalError ? (
        <Result
          status="error"
          title="Aptikta klaida"
          subTitle="Prašome susisiekti su administratoriumi."
        />
      ) : showEndscreen ? (
        <Card style={{ minHeight: "400px", background: '#F6F6F6' }}>
          <Result
            status="success"
            title="Jūsų užsakymas gautas sėkmingai"
            subTitle="Mes gavome jūsų registraciją, artimiausiu metu gausite el. laišką su gimtadienio pakvietimu ir kita informacija."
          />
        </Card>
      )

        : (
          <>
            {nonCriticalError && (
              <Alert
                type="error"
                message="Nepavyko įkelti laiko šiai datai. Kalendorius buvo atstatytas į šiandieną."
                action={
                  <Button type="primary" onClick={handleCalendarErrorReset}>
                    Atstatyti Kalendorių
                  </Button>
                }
                banner
                closable
              />
            )}

            <Card
              style={{
                padding: '5px',
                fontFamily: 'Poppins, sans-serif',
                background: 'rgb(246, 246, 246)',
                position: 'relative',
                textAlign: "center",
                marginBottom: "8px",
                borderWidth: '5px',
                borderStyle: 'solid'
              }}
            >
              <Space>
                {cities.map((city) => (
                  <Button
                    type={selectedCity?.uuid === city.uuid ? "primary" : "default"}
                    key={`${city.uuid}-${city.name}`}
                    onClick={() => handleCityChange(city)}
                  >
                    {city.name}
                  </Button>
                ))}
              </Space>
            </Card>

            {selectedCity ? (
              <>
                <Card
                  style={{
                    padding: '5px',
                    position: 'relative',
                    textAlign: 'center',
                    marginBottom: '8px',
                    borderWidth: '5px',
                    borderStyle: 'solid'
                  }}
                >
                  Pasirinktas miestas: <span style={{ fontWeight: 'bold' }}>{selectedCity.name}</span>
                </Card>

                <CardPartial isPosting={isPosting} active_time={"none"}>
                  <Spin tip="Kraunasi" size="large" spinning={loading}>
                    <Tooltip title="Visą dieną užimta">
                      <Calendar

                        onSelect={calendarChange}
                        defaultValue={startDate}
                        disabledDate={calendarFilter}
                        locale={locale}
                        headerRender={({ value, type, onChange }) => {
                          // Custom calendar header
                          const start = 0;
                          const end = 12;
                          const monthOptions = [];
                          let current = value.clone();
                          const localeData = value.localeData();
                          const months = [];
                          for (let i = 0; i < 12; i++) {
                            current = current.month(i);
                            months.push(localeData.months(current));
                          }

                          for (let i = start; i < end; i++) {
                            monthOptions.push(
                              <Select.Option key={i} value={i} className="month-item">
                                {months[i].toString().charAt(0).toUpperCase() + months[i].toString().slice(1)}
                              </Select.Option>
                            );
                          }

                          const year = value.year();
                          const month = value.month();
                          const options = [];
                          for (let i = year - 10; i < year + 10; i += 1) {
                            options.push(
                              <Select.Option key={i} value={i} className="year-item">
                                {i}
                              </Select.Option>
                            );
                          }
                          return (
                            <div style={{ padding: 8 }}>
                              <Row gutter={8} justify={"end"}>
                                <Col>
                                  <Select
                                    size="small"
                                    className="my-year-select"
                                    value={year}
                                    style={{ minWidth: "120px" }}
                                    onChange={(newYear) => {
                                      const now = value.clone().year(newYear);
                                      onChange(now);
                                    }}
                                  >
                                    {options}
                                  </Select>
                                </Col>
                                <Col>
                                  <Select
                                    size="small"
                                    value={month}
                                    style={{ minWidth: "120px" }}
                                    onChange={(newMonth) => {
                                      const now = value.clone().month(newMonth);
                                      onChange(now);
                                    }}
                                  >
                                    {monthOptions}
                                  </Select>
                                </Col>
                              </Row>
                            </div>
                          );
                        }}
                        validRange={[dayjs(), dayjs().add(30, 'month')]}
                        fullscreen={false}
                      ></Calendar>
                    </Tooltip>
                  </Spin>
                </CardPartial>
              </>
            ) : (
              <Alert type="warning" message="Pirma pasirinkite miestą, kad matytumėte kalendorių" banner />
            )}

            <CardPartial isPosting={isPosting} title="Laisvi laikai" active_time={"none"}>
              <Space style={{ width: '100%', justifyContent: 'center' }} wrap direction="horizontal">
                {selectedCity === null ? (
                  <Alert type="warning" message="Pirma pasirinkite miestą, kad matytumėte laisvus laikus" banner />
                ) : availableTimes.length <= 0 ? (
                  <Alert type="info" message="Jūsų pasirinktai datai nėra laisvų laikų, pasirinkite kitą datą" banner />
                ) : (
                  availableTimes.map((time) => (
                    <Button
                      type={activeTime === time.uuid ? "primary" : "default"}
                      key={time.uuid}
                      onClick={() => {
                        console.log(`Time selected: ${time.uuid}`);
                        setActiveTime(time.uuid);
                        setUserForm({
                          ...userForm,
                          "time_selected": time.uuid
                        });
                        setPrice(price => {
                          oldPrice = price;
                          return price - oldPrice + time.base_price;
                        });
                      }}
                      size="small"
                    >
                      {time.time_start.substring(0, time.time_start.length - 3)}-
                      {time.time_end.substring(0, time.time_end.length - 3)}
                    </Button>
                  ))
                )}
              </Space>
            </CardPartial>

            <CardPartial isPosting={isPosting} title="Kontaktinė informacija" active_time={activeTime}>
              <Space className="w-full" direction="vertical" wrap>
                <InputHolder userForm={userForm} errors={errors} fillableForm={fillableForm} name="customer_name" placeholder="Vaiko vardas" />
                <small style={{ fontSize: "10px" }}>Vaiko vardas naudojamas kvietime, patikrinkite ar be klaidų parašėte vaiko vardą!</small>
                <InputHolder userForm={userForm} errors={errors} fillableForm={fillableForm} name="customer_age" placeholder="Kelintas gimtadienis bus švenčiamas" />
                <InputHolder userForm={userForm} errors={errors} fillableForm={fillableForm} name="customer_guests" placeholder="Planuojamas vaikų skaičius" />
                <div className={`text-red-400 text-xs text-center ${userForm.customer_guests > guestOverflow ? "block" : "hidden"}`}>
                  Jei gimtadienyje dalyvauja daugiau nei {guestOverflow} vaikų, tuomet už kiekvieną papildomą vaiką taikomas 5.00 EUR mokestis.
                </div>
                <InputHolder userForm={userForm} errors={errors} fillableForm={fillableForm} name="customer_parent_name" placeholder="Vieno iš tėvų vardas ir pavardė" />
                <InputHolder userForm={userForm} errors={errors} fillableForm={fillableForm} name="customer_address" placeholder="Gyvenamosios vietos adresas" />
                <InputHolder displayName="Telefono numeris" userForm={userForm} errors={errors} fillableForm={fillableForm} name="customer_phone_no" prefix="+370" placeholder="6000000" />
                <InputHolder userForm={userForm} errors={errors} fillableForm={fillableForm} name="customer_email" placeholder="El. paštas" />
              </Space>
            </CardPartial>

            <CardPartial isPosting={isPosting} title="Papildukai" active_time={activeTime}>
              <Space className="w-full" direction="vertical">
                {services.map((s) => {
                  const isUnavailable = selectedCity?.name === "Alytus" && unavailableServicesInAlytus.includes(s.name);
                  return (
                    <Row key={s.uuid} align={"middle"}>
                      <Col>
                        <Checkbox
                          onChange={(e) => {
                            if (!isUnavailable) {
                              let array: string[] = userForm.services_selected;
                              if (array.includes(s.uuid)) {
                                array.splice(array.indexOf(s.uuid), 1);
                              } else {
                                array.push(s.uuid);
                              }

                              if (e.target.checked) {
                                setPrice((price) => price + s.price);
                              } else {
                                setPrice((price) => price - s.price);
                              }

                              setUserForm({
                                ...userForm,
                                services_selected: array
                              });

                              // Combo selection logic
                              if (array.includes("fe2b259a25da426db77eca7b0aa50ba5") && array.includes("2c16e8c047734c72999241d3c88d481a")) {
                                setCombo(true);
                              } else {
                                setCombo(false);
                              }
                            }
                          }}
                          disabled={isUnavailable} // Disable checkbox if service is unavailable
                        />
                      </Col>
                      <Col flex={"auto"} className="pl-5">
                        {isUnavailable ? (
                          <Tooltip title="Laikinai neturime Alytuje">
                            <span style={{ display: 'inline-block', cursor: 'pointer' }}>
                              {s.name}
                            </span>
                          </Tooltip>
                        ) : (
                          <span>{s.name}</span>
                        )}
                      </Col>
                      <Col className="pr-2">{s.price.toFixed(0)} EUR</Col>
                    </Row>
                  );
                })}
                {combo === true && (
                  <Row align={"middle"}>
                    <small className="text-center w-full text-[#d33092]">
                      <CheckCircleFilled /> Pasirinkus spragėsius ir cukraus vatą kartu taikoma -10 EUR nuolaida
                    </small>
                  </Row>
                )}
              </Space>
            </CardPartial>



            <CardPartial isPosting={isPosting} title="Gimtadienio kvietimai" active_time={activeTime}>
              {errors['invitation_type'] !== undefined && (
                <Alert type="error" className="mb-2" message="Būtina pasirinkti pakvietimą" banner />
              )}
              <Space style={{ width: '100%', justifyContent: 'center' }} wrap direction="horizontal">
                {invitations.length > 0 && invitations.map((invite) => (
                  <Popover
                    placement="top"
                    key={invite.uuid}
                    content={<img src={`${process.env.REACT_APP_API_URL}/api/invitations/${invite.uuid}/thumbnail`} alt="Kvietimas" width={170} />}
                  >
                    <img
                      style={{ maxWidth: "60px" }}
                      className={`rounded-md ${activeInvite !== invite.uuid ? "grayscale" : "border-2 border-green-600"}`}
                      src={`${process.env.REACT_APP_API_URL}/api/invitations/${invite.uuid}/thumbnail`}
                      alt={`Kvietimas nr ${invite.uuid}`}
                      onClick={() => {
                        console.log(`Invitation selected: ${invite.uuid}`);
                        setUserForm({
                          ...userForm,
                          "invitation_type": invite.uuid
                        });
                        setActiveInvite(invite.uuid);
                      }}
                    />
                  </Popover>
                ))}
              </Space>
              <Space className="w-full text-[#D33092]">
                <Checkbox
                  onChange={(e) => {
                    console.log(`Print invites: ${e.target.checked}`);
                    setUserForm({ ...userForm, print_invites: `${e.target.checked}` });
                  }}
                >
                  Užsisakau spausdintus kvietumus (24 vnt.). Patvirtinu, kad atvyksiu juos pasiimti.
                </Checkbox>
              </Space>
            </CardPartial>

            <CardPartial isPosting={isPosting} title="Nuolaidos kodas" active_time={activeTime}>
              <Input
                ref={discountRef}
                placeholder="Nuolaidos kodas"
                name="discount_code"
                onChange={fillableForm}
                style={inputStyle}
                value={userForm.discount_code}
              />
              <div className="text-center w-full">
                <div className="mt-2">
                  {discountStatus !== null && (
                    discountStatus ? (
                      <span className="text-green-600">
                        <CheckCircleFilled /> Nuolaidos kodas pritaikytas
                      </span>
                    ) : (
                      <span className="text-red-600">
                        <CloseCircleFilled /> Nuolaidos kodas nerastas
                      </span>
                    )
                  )}
                </div>
                <Button onClick={handleDiscountCodeCheck} className="mt-2" type="primary">Patikrinti</Button>
              </div>
            </CardPartial>

            <CardPartial isPosting={isPosting} title="Mokėtina suma" active_time={activeTime}>
              <div className="w-full text-5xl text-center text-[#D33092]">
                {price.toFixed(0)}<sup><small>EUR</small></sup>
              </div>
              {totalDiscount > 0 && (
                <div className="w-full text-2xl text-center text-[#66469C]">
                  Suma su nuolaida: {discountedPrice.toFixed(0)}<sup><small>EUR</small></sup>
                </div>
              )}
              <div className="text-center w-full">
                <Button className="mt-5" onClick={postData}>Rezervuoti</Button>
              </div>
            </CardPartial>
          </>
        )}
    </div>
  );
}

export default App;
