import { Card, Spin } from "antd";

function CardPartial(props:any) {


    return (
        <Card className="mt-2" bodyStyle={{padding: "5px", fontFamily:"Poppins, sans-serif", background:"#F6F6F6", position:"relative"}}>
            <Spin style={{background:'#fff'}} spinning={props.isPosting} tip={"Neuždarykite puslapio vyksta duomenų apdorojimas"}>
            <div className={`absolute flex inset-0 z-50 items-center justify-center ${props.active_time === null ? `block` : `hidden`}`} style={{background:'rgba(255, 255, 255, 1)', color:'#204056'}}>
                <span className="text-2xl">
                    Pasirinkite datą ir laiką
                </span>
            </div>
            {props.title ? <div className="w-full text-center text-lg mb-2">{props.title}:</div> : null}
            {props.children}
            </Spin>
        </Card>
    );
}

export default CardPartial;