import ReactDOM from 'react-dom/client';
import './index.css';
import { ConfigProvider } from 'antd';
import ltLT from 'antd/locale/lt_LT';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <GoogleReCaptchaProvider
      reCaptchaKey="6Lfg0TQmAAAAAAg1Jj45IYBWr3wKg4fUqVRtAOuQ"
      language="lt"
    >
      <ConfigProvider locale={ltLT} theme={{
        "token": {
          "colorPrimary": "#66469c",
          "colorSuccess": "#9cc43c",
          "borderRadius": 0,
          "wireframe": true,
          "colorInfo": "#66469c",
          "colorLink": "#d33092",
          "colorError": "#c12d86"
        }
      }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} >
            </Route>
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </GoogleReCaptchaProvider>
  </>
);
