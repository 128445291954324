import { Alert, Input } from "antd";
import { ChangeEventHandler } from "react";
import { UserFormInterface } from '../definitions'

interface objectInterface {
    [key: string]: string
}

interface InputInterface {
    errors: objectInterface,
    fillableForm: ChangeEventHandler<HTMLInputElement>,
    name: string,
    placeholder: string,
    prefix?: string,
    userForm: UserFormInterface,
    displayName?: string
}


function InputHolder({ errors, fillableForm, name, placeholder, prefix, userForm, displayName }: InputInterface) {


    if (displayName === undefined || displayName === "") {
        displayName = placeholder
    }

    const inputStyle: Object = {
        width: "100%"
    };

    let str = name as keyof typeof userForm;

    return (
        <>
            {
                errors[name] !== undefined ?
                    <Alert banner showIcon message={errors[name]} type="error" />
                    :
                    null
            }
            <label>
                {displayName}
            </label>
            <Input prefix={prefix} onChange={fillableForm} value={userForm[str]! !== "" && userForm[str]! !== 0 ? userForm[str]! : ""} name={name} style={inputStyle} />
        </>
    );
}

export default InputHolder;